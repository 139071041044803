import type { Images as ImagesType } from '@academica-box/components/Media/UiPicture/types'
import type { Video as VideoType } from '@academica-box/components/Media/UiVideo/types'
import type { NavSection, NavItem } from '@academica-box/components/Blocks/UiNavigation/types'
import type { Section as FilterSection, FilterItem, FilterMinMax, SortingItem } from '@academica-box/components/Filter/types'
import type { Tag } from '@academica-box/components/Plaques/UiTag/types'
import type { PromoBanner } from '@academica-box/components/Blocks/UiPromoBanner/types'
import type { NavBanner } from '@academica-box/components/Media/UiNavigationBanner/types'
import type { Price } from '@academica-box/components/Cards/UiProductCard/types'
import { type MainBanner, FilterKeys } from '@academica-box/types'

export type Images = ImagesType;

export type Video = VideoType;

export type Section = FilterSection;

export type Direction = NavSection;

export type SectionOfDirection = NavItem;

export type TagNavItem = {
  link: string;
  tag: Tag;
}

export type Filters = {
  [FilterKeys.companies]: Section[] | [];
  [FilterKeys.types]: FilterItem[] | [];
  [FilterKeys.levels]: FilterItem[] | [];
  [FilterKeys.languages]: FilterItem[] | [];
  [FilterKeys.duration]: FilterMinMax;
  [FilterKeys.price]: FilterMinMax;
  [FilterKeys.sorting]: SortingItem[] | [];
  [FilterKeys.certificate]: FilterItem[] | [];
}

export type ChosenFilter = {
  text: string;
  value: string;
  query: FilterKeys;
}

export type SubscribePro = {
  termsLink: string,
  card?: {
    plaque?: {
      tag: Tag;
      tagTextColor: string;
      tagBgColor?: string;
      tagFontWeight?: 400 | 450 | 500;
      tagTooltipContent?: {
        title?: string;
        text?: string;
      };
    };
  };
  banners: {
    main?: PromoBanner;
    page?: MainBanner;
    header?: {
      link: string;
      images: Images;
    };
    navigation?: NavBanner;
  };
}

export enum CoursesOpenBodyAccessTypeEnum {
  Paid = 'paid',
  Free = 'free',
  Trial = 'trial',
}

export type AccessTypeEnum = CoursesOpenBodyAccessTypeEnum
export const AccessTypeEnum = CoursesOpenBodyAccessTypeEnum

export type PaymentData = {
  data: {
    title: string;
    descriptions: string[];
    price: Price;
    program?: Tag;
    free?: Boolean;
    pro?: Boolean;
    trialPro?: Boolean;
  };
  loggedData?: {
    coloredIcon: string;
    icon: string;
    name: string;
    description?: string;
    avatar: Images;
    logged: boolean;
  };
  priceBanner: {
    images: Images;
    bgColor: string;
    description?: string;
  };
} | undefined;
