import { defineStore } from 'pinia'
import type { ButtonThemes } from '@academica-box/types'
import { AccessTypeEnum } from '@/types'

export enum SmartComponents {
  PaymentModal = 'PaymentModal',
}

type State = {
  [SmartComponents.PaymentModal]: {
    accessType: AccessTypeEnum;
    isShow: boolean;
    isSubcribeProgress: boolean;
    isThanksPopupOpen: boolean;
    finishModalData: {
      title: string;
      description: string;
      buttonText: string;
      buttonTheme?: ButtonThemes;
    }
  };
};

export const useSmartComponentsStore = defineStore('smartComponents', {
  state: (): State => ({
    [SmartComponents.PaymentModal]: {
      accessType: AccessTypeEnum.Paid,
      isShow: false,
      isSubcribeProgress: false,
      isThanksPopupOpen: false,
      finishModalData: {
        title: 'Готово!',
        description: 'Открыли доступ <br> к материалам курса',
        buttonText: 'Начать учиться',
      },
    },
  }),

  actions: {
    set<T extends SmartComponents>(componentName: T, data: Partial<State[T]>) {
      this.$state[componentName] = {
        ...this.$state[componentName],
        ...data,
      }
    }
  },
})
